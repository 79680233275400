.listSeeFileData {
  display: flex;
}
.listSeeFileData .listSeeFileData_1 {
  width: 40%;
}
.listSeeFileData .listSeeFileData_1 .listSeeFileData_1_1 {
  width: 80%;
}
.listSeeFileData .listSeeFileData_1 .listSeeFileData_1_2 {
  width: 20%;
  color: #409eff;
  cursor: pointer;
}
.listSeeFileData .listSeeFileData_2 {
  width: 60%;
  border-left: 1px solid #e9e9e9;
  padding-left: 20px;
}
 /deep/ .uploadFile .upload-btn .el-upload {
  height: auto;
  border: none;
}
.uploadFile {
  height: 150px;
  padding: 10px;
  background: #f0f0f0;
  margin-top: 10px;
  position: relative;
}
.uploadFile .applyAnnexList {
  display: flex;
  height: 30px;
  line-height: 30px;
}
.uploadFile .applyAnnexList .applyAnnexList_1 {
  width: 400px;
  line-height: 30px;
}
.uploadFile .applyAnnexList .applyAnnexList_2 {
  color: #409eff;
  margin: 0 10px;
  cursor: pointer;
}
.uploadFile .applyAnnexList .applyAnnexList_3 {
  color: #F56C6C;
  margin: 0 10px;
  cursor: pointer;
}
.uploadFile .cz {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
